export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    width: 60,
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: "产品编号",
    dataIndex: "material__number",
    key: "material__number",
    sorter: true,
    width: 120,
    customRender: (value, item) => item.material_number,
  },
  {
    title: "产品名称",
    dataIndex: "material_name",
    key: "material_name",
  },
  {
    title: "英文名称",
    dataIndex: "material_english_name",
    key: "material_english_name",
  },
  {
    title: "库存数量",
    dataIndex: "quantity",
    key: "quantity",
    width: 80,
  },
  {
    title: "可用数量",
    dataIndex: "available_quantity",
    width: 80,
  },
  {
    title: "单位",
    dataIndex: "material_unit",
    key: "material_unit",
    width: 60,
  },
  {
    title: "包装规格",
    dataIndex: "material_spec",
    key: "material_spec",
    width: 80,
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    width: 80,
    scopedSlots: { customRender: "action" },
  },
];
